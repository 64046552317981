import React from "react"
import {Link} from "gatsby"
import "./product-tile.css"

const ProductTile = ({link, image, name, price, inStock}) => (
  <Link to={link} className="d-block ecwid-product-item h-100 shadow">               
    <div
      className="ecwid-image-wrapper"
    >
    <div className="ecwid-image" style={{
        backgroundImage: `url(${image})`,
      }} />
    {inStock !== undefined && inStock === false ? (
      <div className="stock-banners">
        <div className="d-block text-right mb-1">
          <span className="banner soldout">Sold Out</span>
        </div>
        <div className="d-block text-right mb-1">
          <span className="banner outofstock">Out of Stock</span>
        </div>
      </div>
    ) : null}
    </div>
    <div className="ecwid-product-details">
      <span className="ecwid-product-name d-block">{name}</span>
      <span className="d-block mt-3 h4 ecwid-product-price">
        {price}
      </span>
    </div>
  </Link>
)

	


export default ProductTile