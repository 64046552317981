import React from 'react'

const Value = () =>(
	<div className="value-props">
		<div className="container">
		
			
			<div className="row">
				<div className="col-md-4">
					<div className="value-prop">
						<h4>
							Wide
							<br />
							Selection
						</h4>
						<span>
							Marine lumber, teak veneer plywood,
							accessories, and more!
						</span>
					</div>
				</div>
				<div className="col-md-4">
					<div className="value-prop">
						<h4>
							Specialized
							<br />
							Services
						</h4>
						<span>
							Products and services for customers with
							specialized needs.
						</span>
					</div>
				</div>
				<div className="col-md-4">
					<div className="value-prop">
						<h4>
							Customer
							<br />
							Satisfaction
						</h4>
						<span>
							We pay a lot of attention to making sure
							our customers are satisfied.
						</span>
					</div>
				</div>
			</div>
		
		</div>
		
	</div>
);

export default Value


