import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Swiper from "react-id-swiper"
import Layout from "../layouts"
import About from "../components/homesection/about"
// import Featured from '../components/homesection/featured'
import Value from "../components/homesection/value"
import Subscribe from "../components/homesection/subscribe"
import Shop from "../components/ecwid"
import Slider1 from "../images/slider-1-king-starboard.png"
import Slider2 from "../images/slider-2-name-boards.png"
import Slider3 from "../images/slider-3-teal-molding-accessory.png"
import "../layouts/index.css"
import "../layouts/categories.css"
import "react-id-swiper/src/styles/css/swiper.css"
import FeaturedProducts from "../components/ecwid/FeaturedProducts"
import Category from '../components/ecwid/category'

const IndexPage =({ location, data }) => {
    const parsed = location.hash.split("/", 1)
    console.log(parsed)
    let url = location.pathname
    const params = {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: "next",
        prevEl: "previous",
      },
      spaceBetween: 30,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      speed: 400,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    }


    const featuredProducts = data.allSitePage.edges
    const ecwidCategory = data.allEcwidCategories.nodes

    return (
      <Layout pathname={url}>
        <div className={`hero ${parsed == "#!" ? "d-none" : "d-block"}`}>
          <div className="hero-slider">
            <div className="swiper-board">
              <div className="container">
                    <Swiper {...params}>
                      <div className="swiper-slide">
                        <div className="row">
                          <div className="col-md-6 align-self-center slider-text">
                            <h2>Engraved Name Boards</h2>
                            <h3>
                              Custom and Stock Designs - Teak and King Starboard
                              Color Core
                            </h3>
                            <Link
                              className="btn btn-primary my-3 blue-btn-arrow"
                              to="/NAME-BOARDS-DOCUMENTATION-BOARDS-AND-CUSTOM-SIGNAGE-c46059020"
                            >
                              Explore
                              <i className="fa fa-arrow-right" />
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <img
                              alt="Slider Boards"
                              src={Slider2}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="row">
                          <div className="col-md-6 align-self-center slider-text">
                            <h2>
                              King Starboard Tackle Centers &amp; Enclosures
                            </h2>
                            <h3>Custom and Stock Designs</h3>
                            <Link
                              className="btn btn-primary my-3 blue-btn-arrow"
                              to="/KING-STARBOARD-SHEET-GOODS-c46061016"
                            >
                              Explore
                              <i className="fa fa-arrow-right" />
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <img
                              alt="Slider Boards"
                              src={Slider1}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="row">
                          <div className="col-md-6 align-self-center slider-text">
                            <h2>Over 65 Teak Molding and Accessory Products</h2>
                            <Link
                              className="btn btn-primary my-3 blue-btn-arrow"
                              to="/TEAK-MOLDING-AND-ACCESSORIES-c46059013"
                            >
                              Explore
                              <i className="fa fa-arrow-right" />
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <img
                              alt="Slider Boards"
                              src={Slider3}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </Swiper>
                  
              </div>
            </div>
          </div>
        </div>
        <div className={parsed == "#!" ? "d-none" : "d-block"}>
          <Value />
        </div>

        <div className="my-5">
          <div className="container">
           
                <div className="row">
                  <div className="col-sm-5 col-md-4 col-lg-3 mb-3 mb-md-0">
                    <Category data={ecwidCategory} />
                  </div>
                  <div className="col-sm-7 col-md-8 col-lg-9">
                    <FeaturedProducts data={featuredProducts || []} />
                  </div>  
                </div>
              
          </div>
          
          
        </div>

        {/* <Shop location={parsed} hash={this.props.location.hash}/> */}
        <About />
        <Subscribe />
      </Layout>
    )
  }

export default IndexPage

export const categorynodeQuery = graphql`
  query {
    allStrapiCategories(sort: { fields: id, order: ASC }) {
      edges {
        node {
          id
          name
          url
          subcategories {
            name
            url
          }
        }
      }
    }
    allEcwidProducts(filter: { enabled: { eq: true } }) {
      nodes {
        ecwid_id
        name
        showOnFrontpage
        defaultDisplayedPriceFormatted
        imageUrl
      }
    }
    allSitePage(filter: {context: {showOnFrontpage: {ne: null}}}) {
      edges {
        node {
          context {
            sku
            thumbnailUrl
            quantity
            unlimited
            inStock
            name
            price
            priceInProductList
            defaultDisplayedPrice
            defaultDisplayedPriceFormatted
            isShippingRequired
            weight
            url
            created
            updated
            createTimestamp
            updateTimestamp
            productClassId
            enabled
            warningLimit
            fixedShippingRateOnly
            fixedShippingRate
            defaultCombinationId
            imageUrl
            smallThumbnailUrl
            hdThumbnailUrl
            originalImageUrl
            description
            defaultCategoryId
            seoTitle
            seoDescription
            showOnFrontpage
            isSampleProduct
            googleItemCondition
            isGiftCard
            discountsAllowed
            nameYourPriceEnabled
            productCondition
            options {
              type
              name
              defaultChoice
              required
              choices {
                text
                priceModifier
                priceModifierType
              }
              nameTranslated {
                en
              }
            }
            categoryIds
            galleryImages {
              url
              thumbnailUrl
              thumbnail
              hdThumbnailUrl
              height
              id
              imageUrl
              orderBy
              originalImageUrl
              smallThumbnailUrl
              width
            }
            media {
              images {
                id
                image1500pxUrl
                image160pxUrl
                image800pxUrl
                image400pxUrl
                orderBy
                isMain
                imageOriginalUrl
              }
            }
            relatedProducts {
              relatedCategory {
                categoryId
                enabled
                productCount
              }
            }
            originalImage {
              url
            }
            nameTranslated {
              en
            }
            dimensions {
              height
              length
              width
            }
            descriptionTranslated {
              en
            }
            tax {
              defaultLocationIncludedTaxRate
              taxable
            }
            shipping {
              type
              methodMarkup
              flatRate
              enabledMethods
              disabledMethods
            }
            categories {
              id
              enabled
            }
            combinations {
              options {
                name
                value
                valueTranslated {
                  en
                }
              }
              combinationNumber
              id
              inStock
              price
              quantity
              unlimited
              warningLimit
              weight
              defaultDisplayedPriceFormatted
              defaultDisplayedPrice
            }
          }
          id
        }
      }
    }
    allEcwidCategories {
      nodes {
        ecwid_id
        id
        parentId
        name
        url
      }
    }
  }
`
