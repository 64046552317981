import React from 'react'

const Subscribe = () => (
	<div className="subscribe-section">
		<div className="container slim-content text-center">
			<div>
				<h2 className="text-center section-heading">Stay Connected</h2>
				<p className="text-center my-4">
					Enter your email address to receive special offers and promotions.
				</p>
			</div>
			<div className="text-center">
				<center>
					<form
						className="form-inline text-center mx-auto subcribe-form"
						action="http://emailmarketing.webriq.services/contact/add?version=2"
						data-id="zkrJlVzmTE"
						method="post"
						id="ewf_subscriptionForm_zkrJlVzmTE"
					>
						<fieldset className="webform-custominputs">
							<div className="form-group subscribe-email">
								<label htmlFor="email" className="d-block" />
								<input
									className="form-control"
									type="email"
									name="email"
									id="email"
									required=""
									placeholder="Your Email"
								/>

								<button
									type="submit"
									name="submit"
									value="Subscribe"
									id="eesubmit"
									className="btn btn-primary blue-btn"
								>
									Subscribe
								</button>
							</div>
							<div className="checkboxes" />
							<div className="webform-lists" style={{ display: 'none' }}>
								<div style={{ display: 'none' }}>
									<label className="container-box">
										<input
											type="checkbox"
											name="publiclistid"
											id="KLuSXWci"
											value="23288a15-0cd9-45f4-9624-5bbde00fd15f"
											checked="checked"
										/>
									</label>
									<label className="publiclistlabel" htmlFor="KLuSXWci">
										Subscribers - Offers &amp; Promotions
									</label>
								</div>
							</div>
						</fieldset>

						<fieldset className="webform-options">
							{/*				<button className="btn btn-primary blue-btn" type="submit" id="eesubmit">
							Subscribe
						</button>*/}

							<input
								type="hidden"
								name="publicaccountid"
								value="60f32bc7-830e-4fd1-a823-c207c9064349"
							/>
							<input
								type="hidden"
								name="publicformid"
								value="4972ced9-6aed-4324-bed8-e8e51eecd2f7"
							/>
							<input type="hidden" name="returnUrl" value="" />
							<input type="hidden" name="activationReturnUrl" value="" />
							<input type="hidden" name="alreadyactiveurl" value="" />
							<input type="hidden" name="activationTemplate" value="" />
							<input type="hidden" name="source" value="WebForm" />
							<input
								type="hidden"
								id="ewf_captcha"
								name="captcha"
								value="false"
							/>
							<input type="hidden" name="notifyEmail" value="" />
							<button
								type="button"
								name="dismissForm"
								id="ewf_dismissForm"
								style={{
									display: 'none',
								}}
							>
								X
							</button>
						</fieldset>
					</form>
				</center>
			</div>
		</div>
	</div>
)

export default Subscribe
