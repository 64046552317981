import React from 'react';
import './ecwid.css'


class Ecwid extends React.Component {
  // loadScriptAsync = (uri) => {
  //   return new Promise((resolve,reject) => {
  //     const s = document.createElement('script');
  //     s.src = uri;
  //     s.id = `ecwid-script`
  //     s.setAttribute("data-cfasync", false);
  //     s.async = true;
  //     s.charset = "utf-8";
  //     s.onload =() => {
  //       resolve();
  //     }
  //     // const script = document.getElementsByTagName('script')[0]
  //     // script.parentNode.insertBefore(s, script);
  //     // document.getElementById('ecwid-shop-store').appendChild(s);
  //     document.body.appendChild(s);

  //   })
  // }
  


  componentDidMount() {
    window.ecwid_script_defer = true;
    window.ecwid_dynamic_widgets = true;

    new Promise((resolve, reject) => {
      setTimeout(resolve, 100);
    }).then(function() {
        window._xnext_initialization_scripts = [
          {
            widgetType: 'ProductBrowser',
            id: 'ecwid-shop-store',
            arg: ["id=ecwid-shop-store"]
          },
          { 
            widgetType: 'CategoriesV2', 
            id: 'ecwid-shop-categories', 
            arg: ["id=ecwid-shop-categories"] 
          },
          { 
            widgetType: 'SearchWidget', 
            id: 'ecwid-shop-search', 
            arg: ["id=ecwid-shop-search"] 
          }
        ];
        setTimeout(function() {
          window.Ecwid.init();
        }, 2000)
    })

    if (!document.getElementById('ecwid-script')) {
        var script = document.createElement('script');
        script.charset = 'utf-8';
        script.type = 'text/javascript';
        script.src = 'https://app.ecwid.com/script.js?24652016&data_platform=code&data_date=2020-03-03';
        script.id = 'ecwid-script'
        document.body.appendChild(script);
      } else {
        window.Ecwid._onBodyDone();
    }
  }
  componentWillUnmount() {
    console.log("unmount")
    if (typeof Ecwid !== 'undefined') window.Ecwid.destroy();
  }
  render() {
    const { location, hash } = this.props
    console.log("path", hash.startsWith("#!/~/orderConfirmationasdfasdf"))

    return (
      <div className="featured-section pt-5" id="featured">
      <div className="container">
      
      <div className="row">
        <div className={`col-lg-3 mb-5 ${hash == "#!/~/cart" || 
                                         hash == "#!/~/checkoutAddress" || 
                                         hash == "#!/~/checkoutDelivery" || 
                                         hash == "#!/~/checkoutPD" || 
                                         hash.startsWith("#!/~/orderConfirmation") === true ? "d-none" : "d-block"}`}>
          <div className="intro mb-4">
            <h2 className="section-heading m-0">Categories</h2>
          </div>
          <div id="ecwid-shop-search" className="mb-4 pt-3"></div>
          <div id="ecwid-shop-categories"></div>
        </div>
        <div className="col-lg-9 mx-auto">
          <div>
            <div className="intro d-flex align-items-center mb-5">
              <h2 className={`section-heading px-lg-4 ${location == "#!" ? "d-none" : "d-block"}`}>Featured Items</h2>  
            </div>
            <div className="featured-items">
              <div id="ecwid-shop-store"></div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
  
    );
  }
}

export default Ecwid