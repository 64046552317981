import React from 'react'
import { Link } from 'gatsby'

const About = () => (
	<div className="about">
		<div className="container">
		 
		  	
			<div className="row">
				<div className="col-md-6 ml-auto">
					<div>
						<p>
							Buck Woodcraft offers a wide selection of marine lumber, teak
							molding, teak veneer plywood, King Starboard marine plastics and
							accessories – as well as custom and stock tackle centers,
							enclosures, boat doors and custom woodworking services.
						</p>
						{/*<button
							className="btn btn-primary white-transparent-btn info-btn mr-3 mt-3"
							type="button"
						>
							<i className="fa fa-info-circle" />
						</button>*/}

						<Link
							to="/shop"
							className="btn btn-primary blue-btn-arrow mt-3 shopbutton"
						>
							Go to Shop
							<i className="fa fa-arrow-right" />
						</Link>
					</div>
				</div>
			</div>
		
		
		</div>
	</div>
)

export default About
