import { Link } from "gatsby"
import React, { useState } from "react"
import slugify from "slugify"
import JwPagination from "../pagination/pagination"
import ProductTile from "./productTile"
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export default function FeaturedProducts({ data }) {
  function createSlug(text) {
    return slugify(text, {
      replacement: "-", // replace spaces with replacement
      remove: /[*+~.()'"!:@?]/g, // regex to remove characters
      lower: true, // result in lower case
    })
  }

  

  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage=(pageOfItems)=>{
    setPageOfItems(pageOfItems)
  }

  // console.log("pageOfItems", pageOfItems)
  return (
    <>
      <h2 className="section-heading d-block mb-4">Featured Items</h2>
      <div className="row">
        {pageOfItems.map((item, index) => (
          <div className="col-md-6 col-lg-4 mb-5" key={index}>
            <LazyLoadComponent>
              <ProductTile
                link={item.node.context.url}
                image={item.node.context.imageUrl}
                name={item.node.context.name}
                price={item.node.context.defaultDisplayedPriceFormatted}
              />
            </LazyLoadComponent>
          </div>
        ))}
      </div>

      <JwPagination
        items={data}
        onChangePage={onChangePage}
        pageSize={9}
      />
    </>
  )
}
